import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { ReactNode } from 'react'

import SubtractBlock from '~/components/shared/SubtractBlock'

import * as containerStyles from './PrimeSectionLeadMagnets.module.scss'

interface PrimeSectionLeadMagnetsProps {
  title: string
  description?: ReactNode
  maxWidthTitle?: string
  maxWidthDescription?: string
  isInfo?: boolean
  infoImage?: IGatsbyImageData | undefined
  isButton?: boolean
  buttonLink?: string
  buttonLinkName?: string
  isDownload?: boolean
  downloadId?: string
  downloadFile?: string
  downloadBtnName?: string
}

const PrimeSectionLeadMagnets = ({
  title,
  description,
  maxWidthTitle,
  maxWidthDescription,
  isInfo,
  infoImage,
  isButton,
  buttonLink,
  buttonLinkName,
  isDownload,
  downloadId,
  downloadFile,
  downloadBtnName,
}: PrimeSectionLeadMagnetsProps) => (
  <div className={containerStyles.primeLeadMagnetsSection}>
    <div className="container container-md">
      <div className="row">
        <div className="col-12">
          <h1
            className={containerStyles.primeLeadMagnetsSection__title}
            style={{ maxWidth: maxWidthTitle }}
          >
            {title}
          </h1>

          {isDownload && (
            <a
              href={downloadFile}
              rel="noopener noreferrer"
              target="_blank"
              data-id={downloadId}
              className={containerStyles.primeLeadMagnetsSection__button}
              download
            >
              {downloadBtnName}
            </a>
          )}

          {description && (
            <p
              className={containerStyles.primeLeadMagnetsSection__description}
              style={{ maxWidth: maxWidthDescription }}
            >
              {description}
            </p>
          )}

          {isButton && (
            <a
              href={buttonLink}
              className={containerStyles.primeLeadMagnetsSection__button}
              style={{ marginTop: 30 }}
            >
              {buttonLinkName}
            </a>
          )}

          {isInfo && (
            <div className={containerStyles.primeLeadMagnetsSection__info}>
              <div className={containerStyles.primeLeadMagnetsSection__infoImg}>
                <GatsbyImage
                  image={infoImage as IGatsbyImageData}
                  alt="Dmytro, CEO at Codica"
                  title="Dmytro, CEO at Codica"
                />
              </div>
              <div className={containerStyles.primeLeadMagnetsSection__infoTxt}>
                <p>I am Dmytro, CEO at Codica.</p>
                <p>
                  We will be in touch soon to discuss your project and see how
                  we can help bring your idea to reality.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>

    <SubtractBlock />
  </div>
)

export default PrimeSectionLeadMagnets
