import HeadSection from '~/components/blocks/HeadSection'
import RecentArticles from '~/components/blocks/RecentArticles'
import SocialBlock from '~/components/blocks/SocialBlock'
import MainLayout from '~/components/layouts/MainLayout'
import { AllArticlePageProps } from '~/types/common-props'
import PrimeSectionLeadMagnets from '~/views/EbookLandings/components/PrimeSectionLeadMagnets'

const ResumeThanks = ({ data }: AllArticlePageProps) => (
  <MainLayout isTransparent>
    <PrimeSectionLeadMagnets
      title="Thank you!"
      description="We are happy to hear from you. We’ll get in touch with you as soon as possible."
      maxWidthTitle="300px"
      maxWidthDescription="450px"
      isButton
      buttonLink="/"
      buttonLinkName="Get back to homepage"
    />

    <section style={{ margin: '70px 0 50px' }}>
      <HeadSection
        position="left"
        title="In the meantime, take a look at our most recent articles:"
      />
      <RecentArticles data={data} buttonText="Explore Codica Blog" />
    </section>

    <SocialBlock iconsCount={6} />
  </MainLayout>
)

export default ResumeThanks
