// extracted by mini-css-extract-plugin
export var socialBlock = "cY_v0";
export var socialBlock__icon = "cY_v5";
export var socialBlock__iconWrapper = "cY_v4";
export var socialBlock__icon_facebook = "cY_v6";
export var socialBlock__icon_instagram = "cY_v9";
export var socialBlock__icon_linkedin = "cY_v7";
export var socialBlock__icon_telegram = "cY_wb";
export var socialBlock__icon_tiktok = "cY_wc";
export var socialBlock__icon_twitter = "cY_v8";
export var socialBlock__iconsWrapper = "cY_v3";
export var socialBlock__sprite = "cY_v2";
export var socialBlock__title = "cY_v1";