import { useAddClassNameWhenVisible } from '~/hooks/useAddClassNameWhenVisible'
import { cx } from '~/utils'

import * as containerStyles from './SocialBlock.module.scss'

const socialBlockIcons = [
  {
    key: 'facebook',
    href: 'https://www.facebook.com/CodicaCom',
    className: containerStyles.socialBlock__icon_facebook,
  },
  {
    key: 'twitter',
    href: 'https://twitter.com/codicacom',
    className: containerStyles.socialBlock__icon_twitter,
  },
  {
    key: 'linkedin',
    href: 'https://www.linkedin.com/company/codica-com/',
    className: containerStyles.socialBlock__icon_linkedin,
  },
  {
    key: 'instagram',
    href: 'https://www.instagram.com/codica_com/',
    className: containerStyles.socialBlock__icon_instagram,
  },
  {
    key: 'telegram',
    href: 'https://t.me/codica',
    className: containerStyles.socialBlock__icon_telegram,
  },
  {
    key: 'tiktok',
    href: 'https://www.tiktok.com/@codica_com',
    className: containerStyles.socialBlock__icon_tiktok,
  },
]

interface SocialBlockProps {
  iconsCount?: number
}

const SocialBlock = ({ iconsCount = 3 }: SocialBlockProps) => (
  <div className={containerStyles.socialBlock}>
    <div className="container container-md">
      <div className="row">
        <div className="col-12">
          <div className={containerStyles.socialBlock__title}>
            Want to know more about us? Check our socials:
          </div>
          <div className={containerStyles.socialBlock__iconsWrapper}>
            {socialBlockIcons
              .slice(0, iconsCount)
              .map(({ className, href, key }) => {
                const { ref } = useAddClassNameWhenVisible<HTMLDivElement>({
                  targetClassName: className,
                  loadedClassName: containerStyles.socialBlock__sprite,
                })

                return (
                  <div
                    ref={ref}
                    key={key}
                    className={containerStyles.socialBlock__iconWrapper}
                  >
                    <a
                      href={href}
                      aria-label={key}
                      className={cx(
                        containerStyles.socialBlock__icon,
                        className,
                      )}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default SocialBlock
